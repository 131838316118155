@import '~antd/dist/antd.less';

@media print {
  @page {
    size: 21cm 29.7cm;
    margin: 18mm;
  }
}

body th.ant-table-cell,
.ant-card-head,
.ant-collapse-header {
  background-color: #d9e2ec !important;
}

.ant-card-bordered {
  border-color: #d9e2ec !important;
}

.ant-form-item-label label {
  font-weight: 600;
}

@font-family: droid-sans,sans-serif;